import React from 'react';
import config from 'Config';

const Brand = props => {
  return (
    <div className={props.className}>
    <div className="explore-brandes-section" style={{backgroundImage: `url(${(props.screenType === 'small' && props.data.data.mimage !== '' ? props.data.data.mimage : props.data.data.image)})`}}>
      <div className={config.name === 'sb' ? 'section-container-1140' : 'section-container'}>
        <div className="explore-brandes-content-row">
          <div className="explore-brandes-content-title">
            {props.data.data.title}
          </div>
          <div className="explore-brandes-logo-row">
            {props.data.data.logo.map((k, index) => {
              return (
                <a href={k.url} key={`${k.brand}`}>
                  <div className="explore-brandes-logo-holder">
                    <img src={k.brand} alt="" className="global-image" />
                  </div>
                </a>
              )
            })}
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Brand
