import { css } from '@emotion/core'
import theme from 'Theme'
const { mixins } = theme

export const plain = () => css`
  &.loading {
    height: 450px;
    ${mixins.mainContainerMaxWidth(theme)}
    ${mixins.loadingLight(theme)}
  }
`

export default plain
