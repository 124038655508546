import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import {
  skin,
  withGAEvent,
  contentCardGALabel,
  createContentCardGAProps,
  path,
  sanitize,
  cutTitleToLimit
} from 'Utils'
import get from 'lodash/get'
import skins from '../skins'
import config, { gaSettings } from 'Config'
import { Link, LazyLoadImage } from 'Blocks'
import theme from 'Theme'

const { layout } = theme

const mapStateToProps = state => {
  return {
    screenType: state.UI.screenType
  }
}

const ContentListItem = ({
  className = '',
  pageName = '',
  item,
  position = 0,
  variant = ''
}) => {
  const { type, image, uuid, slug } = item

  const imageWidth =
    variant === 'featured' ? '500' : layout.maxWidth.mainContainer

  const cardGASettings = gaSettings[type].ContentCard
  const labelProps = {
    ...{ uuid },
    ...{ type },
    ...{ position }
  }
  const label = contentCardGALabel(labelProps)
  const additionalProps = createContentCardGAProps(labelProps)
  const GALink = withGAEvent(Link, {
    action: cardGASettings.action,
    category: cardGASettings.category.replace('$pageName', pageName),
    label: label.join(' - '),
    additionalProps,
  })

  const { withExtraInfo, withSubTitle } = config.articleCard
  const { showSponsoredBy } = config.article
  let subText = ''
  const themeName = get(item, 'theme', '')
  const isSponsored = get(item, 'sponsor', false)

  if (themeName) {
    subText = themeName
  }

  if (showSponsoredBy && isSponsored) {
    subText = themeName ? `${subText} - Sponsored` : 'Sponsored'
  }

  const sanitizedShortTitle = cutTitleToLimit(sanitize(item.title.short), 30)
  const sanitizedLongTitle = sanitize(item.title.long)

  return (
    <GALink
      to={path(type, { uuid, slug })}
      className={`${className} content-list-item`}
    >
      <LazyLoadImage
        className="content-list-item__image"
        image={variant === 'hero' ? image.hero : image.main}
        width={imageWidth}
        crop="fill"
      />
      <div className="content-list-item__content-wrapper">
        {withExtraInfo && (
          <h3 className="content-list-item__category-name">{subText}</h3>
        )}
        <h2 className="content-list-item__short-title">
          {sanitizedShortTitle}
        </h2>
        {withSubTitle && (
          <p className="content-list-item__long-title">{sanitizedLongTitle}</p>
        )}
      </div>
    </GALink>
  )
}

ContentListItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    image: PropTypes.shape({
      hero: PropTypes.any,
      main: PropTypes.any
    }),
    slug: PropTypes.string,
    title: PropTypes.shape({
      long: PropTypes.string,
      short: PropTypes.string
    }),
    type: PropTypes.string,
    uuid: PropTypes.string
  }),
  pageName: PropTypes.string,
  position: PropTypes.number,
  screenType: PropTypes.string,
  variant: PropTypes.string
}

export default skin(connect(mapStateToProps, null)(ContentListItem), skins)
