import PropTypes from 'prop-types'
import React from 'react'
import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import { GET_HERO_CONTENT_CARD } from 'Queries'
import { ErrorView, ContentListItem } from 'Blocks'
import { skin, getThemeFromQueryString } from 'Utils'
import { withRouter } from 'react-router'
import skins from './skins'

const HeroArticleCardContainer = props => {
  const Loading = () => <div className={`${props.className} loading`}></div>
  const variables = {
    arguments: {
      isHero: true,
      state: 'PUBLISHED'
    }
  }
  const { loading, error, data } = useQuery(GET_HERO_CONTENT_CARD, {
    variables
  })
  if (loading) return <Loading />
  if (error) return <ErrorView message={error.message} error={error} />
  const heroArticle = get(data, 'content', {})
  const heroImage = get(heroArticle, 'image.hero', false)
  const location = get(props, 'location', null)
  const currentTheme = getThemeFromQueryString({ location })
  const heroArticleTheme = get(heroArticle, 'theme', '')

  if (currentTheme && heroArticleTheme !== currentTheme) return null

  return heroArticle && heroImage ? (
    <ContentListItem className="featured" variant="hero" item={heroArticle} />
  ) : null
}

HeroArticleCardContainer.propTypes = {
  className: PropTypes.any
}

export default withRouter(skin(HeroArticleCardContainer, skins))
