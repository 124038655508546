import { css } from '@emotion/core'
import theme from 'Theme'
import { smallOnly, mediumUp } from 'Utils'
import featuredVariant from './featuredVariant'

const { colors, typography, spacing, layout, mixins } = theme

const defaultSkin = () => css`
  height: 450px;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;

  @media ${mediumUp} {
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .content-list-item__image {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 450px;
    width: 100%;
    z-index: 1;
  }

  .content-list-item__content-wrapper {
    max-width: ${layout.maxWidth.mainContainer}px;
    position: relative;
    z-index: 3;
    width: 100%;
    color: white;
    padding: 1rem;
    @media ${smallOnly} {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 450px;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.738) 0%,
      rgba(0, 0, 0, 0.538) 19%,
      rgba(0, 0, 0, 0.441) 34%,
      rgba(0, 0, 0, 0.382) 47%,
      rgba(0, 0, 0, 0.278) 56.5%,
      rgba(0, 0, 0, 0.194) 65%,
      rgba(0, 0, 0, 0.126) 73%,
      rgba(0, 0, 0, 0.075) 80.2%,
      rgba(0, 0, 0, 0.042) 86.1%,
      rgba(0, 0, 0, 0.021) 91%,
      rgba(0, 0, 0, 0.008) 95.2%,
      rgba(0, 0, 0, 0.002) 98.2%,
      transparent 100%
    );
    z-index: 2;
  }

  .content-list-item__short-title {
    text-transform: uppercase;
    font-size: 34px;
    line-height: 1.1;
  }

  .content-list-item__short-title,
  .content-list-item__long-title {
    max-width: 260px;
    @media ${mediumUp} {
      max-width: 340px;
    }
  }

  .content-list-item__category-name {
    ${mixins.subText(theme)};
    font-weight: normal;
    color: white;
  }

  .content-list-item__short-title,
  .content-list-item__category-name {
    margin-bottom: ${spacing.base_1}px;
  }

  @media ${mediumUp} {
    &:hover {
      &::before {
        background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.738) 0%,
          rgba(0, 0, 0, 0.638) 50%,
          rgba(0, 0, 0, 0.541) 60%,
          rgba(0, 0, 0, 0.482) 70%,
          rgba(0, 0, 0, 0.378) 80%,
          rgba(0, 0, 0, 0.294) 90%,
          rgba(0, 0, 0, 0.15) 95%,
          rgba(0, 0, 0, 0.01) 98.2%,
          transparent 100%
        );
        z-index: 2;
      }
    }
  }
`

export default props => {
  if (props.variant === 'featured') {
    return featuredVariant()
  } else {
    return defaultSkin()
  }
}
