import { css } from '@emotion/core'
import theme from 'Theme'
import { mediumUp } from 'Utils'

const { typography, colors, mixins } = theme

const featuredVariant = () => css`
  font-size: ${typography.baseFontSize};
  display: flex;
  background-color: ${colors.bodyBackground};
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 0;
  }

  @media ${mediumUp} {
    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }

  .content-list-item__content-wrapper {
    min-height: 120px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 30px 10px;
    position: relative;
    width: 50%;
  }

  .content-list-item__short-title {
    ${mixins.sectionHeading(theme)}
    font-size: 18px;
    text-transform: capitalize;
    @media ${mediumUp} {
      font-size: 18px;
    }
    margin-bottom: 10px;
  }

  .content-list-item__short-title,
  .content-list-item__category-name {
    margin-left: auto;
    margin-right: auto;
  }

  .content-list-item__short-title {
    max-width: 80%;
  }

  .content-list-item__category-name {
    width: 80%;
    max-width: 240px;
  }

  .content-list-item__category-name {
    position: absolute;
    bottom: 10px;
    width: 100%;
    ${mixins.subText(theme)}
  }

  .content-list-item__image {
    object-fit: cover;
    width: 50%;
    height: 265px;
  }

  .content-list-item__content-wrapper {
    order: 2;
  }

  .content-list-item__image {
    order: 1;
  }
`

export default featuredVariant
