import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { GET_CONTENTS, getArticlesCustomQueryVariables } from 'Queries'
import { path } from 'Utils';

const FeaturedItems = props => {
  const [items, setItems] = useState({});
  let cnt = 0;

  let as = [];
  let ext = {};
  if(props.data.data.isFeatured !== '') {
    as.push(props.data.data.isFeatured);
  }

  props.data.data.articles.map((k) => {
    if(k) {
      as.push(k);
    }
  })

  const variables = getArticlesCustomQueryVariables(
    '',
    5,
    'article',
    false,
    '',
    as
  )
  const { loading, error, data } = useQuery(GET_CONTENTS, { variables });

  useEffect(() => {
    if(data) {
      if(data.contents && data.contents.contents.length > 0) {
        let i = {}
        data.contents.contents.map((k) => {
          i[k.uuid] = k;
        })
        setItems(i);
      }
    }
  }, [data]);

  const LoadURL = (type, uuid, slug) => {
    return path(type, { uuid, slug });
  }

  const LoadGallery = () => {
    const content = Object.keys(items).map((i) => {
      if(props.data.data.isFeatured === items[i].uuid) return null;

      return (
        <div className="vertical-block-right-content-holder" key={`fi-${items[i].uuid}`}>
          <div className="vertical-right-image-block">
            <Link to={LoadURL(items[i].type, items[i].uuid, items[i].slug)} className="vertical-right-content-link">
              <img src={items[i].image.main} alt="" className="global-image" />
            </Link>
          </div>
          <div className="vertical-right-content-block">
            <div className="vertical-right-content-title">{items[i].title.short}</div>
            <div className="vertical-right-content-description">{items[i].title.long}</div>
            <div className="vertical-right-content-link-row">
              <Link to={LoadURL(items[i].type, items[i].uuid, items[i].slug)} className="vertical-right-content-link">Read more</Link>
            </div>
          </div>
        </div>
      )
    })

    return content
  }

  if(items.length == 0) return null;

  return (
    <div className={props.className}>
    <div className="vertical-blocks-section">
      <div className="section-container-1140">
        <div className="vertical-blocks-content-row">
          {items[props.data.data.isFeatured] &&
            <div className="vertical-blocks-left-content">
              <div className="vertical-left-image-block">
                <Link to={LoadURL(items[props.data.data.isFeatured].type, items[props.data.data.isFeatured].uuid, items[props.data.data.isFeatured].slug)} className="vertical-left-block-link">
                  <img src={items[props.data.data.isFeatured].image.main} alt="" className="global-image" />
                </Link>
              </div>
              <div className="vertical-left-block-title">{items[props.data.data.isFeatured].title.short}</div>
              <div className="vertical-left-block-description">{items[props.data.data.isFeatured].title.long}</div>
              <div className="vertical-left-block-link-row">
                <Link to={LoadURL(items[props.data.data.isFeatured].type, items[props.data.data.isFeatured].uuid, items[props.data.data.isFeatured].slug)} className="vertical-left-block-link">Read more</Link>
              </div>
            </div>
          }

          <div className="vertical-blocks-right-content">
            <LoadGallery />
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default FeaturedItems
