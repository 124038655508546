import React, { useState } from 'react';
import Slider from 'react-slick';
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { GET_CONTENTS, getArticlesCustomQueryVariables } from 'Queries'
import { path } from 'Utils';

const ItemManager = props => {
  let nItems = [];
  const variables = getArticlesCustomQueryVariables(
    '',
    5,
    'article',
    true,
    props.data.data.theme,
    nItems,
    'yes'
  )
  const { loading, error, data } = useQuery(GET_CONTENTS, { variables });

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          centerPadding: '20px',
          slidesToShow: 2.5
        }
      },
      {
        breakpoint: 580,
        settings: {
          centerMode: true,
          centerPadding: '10px',
          slidesToShow: 1.15
        }
      }
    ]
  };

  const LoadURL = (type, uuid, slug) => {
    return path(type, { uuid, slug });
  }

  if(!data) return null;

  return (
    <div className={props.className}>
    <div className="content-slider-section">
      <div className="section-container-1140">
        <div className="section-heading-vertical">{props.data.data.theme}</div>
        <Slider {...settings} className="section-slider-row slick-dotted">
          {data.contents.contents.map((k) => (
            <div className="slider-card" key={k.uuid}>
              <div className="slider-card-image-block">
                <div className="content-tag tag-blue">{k.theme}</div>
                <Link to={LoadURL(k.type, k.uuid, k.slug)} className="slider-card-link">
                  <img src={k.image.main} alt="" className="global-image" />
                </Link>
              </div>
              <div className="slider-card-title">{k.title.short}</div>
              <div className="slider-card-description">{k.title.long}</div>
              <div className="slider-card-link-holder">
                <Link to={LoadURL(k.type, k.uuid, k.slug)} className="slider-card-link">Read more</Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
    </div>
  )
}

export default ItemManager
