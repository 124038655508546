import { css } from '@emotion/core'
import { mediumUp, smallOnly, largeUp } from 'Utils'
import theme from 'Theme'

const { mixins, colors, typography, spacing } = theme

const { sectionHeading, mainContainerMaxWidth } = mixins

export default () => css`
  .container__outer {
    &--black {
      background-color: black;
      .content-card {
        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  /*  no hubs */
  .content-list__articles + .container__outer--black {
    margin-top: ${spacing.base_3}px;
  }

  .content-list__articles,
  .content-list__trends,
  .content-list__categories,
  .content-list__instalooks {
    ${mainContainerMaxWidth(theme)}
  }

  .hub-list {
    margin-bottom: ${spacing.base_3}px;
  }

  .content-list__instalooks {
    padding-bottom: ${spacing.base_6}px;
  }

  .container__outer--grey {
    background-color: ${colors.cardGrey};
    margin-top: ${spacing.base_3}px;
    padding-bottom: ${spacing.base_3}px;
  }

  .content-list__articles {
    &.second {
      .content-list__grid {
        @media ${smallOnly} {
          padding-top: ${spacing.base_2}px;
          border-top: 1px solid ${colors.borderGrey};
        }
      }
    }
  }

  /* footer */
  .home-footer__wrapper {
    background-color: white;
  }

  .home-footer {
    ${mainContainerMaxWidth(theme)}
    padding: ${spacing.vertical.medium} 0;

    @media ${largeUp} {
      display: flex;
      .home-footer__heading {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 30%;
      }
      .home-footer__grid {
        width: 100%;
      }
    }
  }

  .home-footer__heading {
    ${sectionHeading(theme)}
    font-size: ${typography.sizes.larger};
    @media ${smallOnly} {
      padding-bottom: ${spacing.vertical.small};
    }
    @media ${mediumUp} {
      text-align: center;
      margin-bottom: ${spacing.base_1};
    }
    @media ${largeUp} {
      font-size: ${typography.sizes.large};
    }
  }

  .home-footer__grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .home-footer__store {
      padding: ${spacing.vertical.small} 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 50%;

      img {
        width: 100%;
        max-width: 150px;
        max-height: 75px;
        object-fit: contain;
      }

      @media ${mediumUp} {
        width: 20%;
        max-width: 200px;
      }

      @media ${largeUp} {
        width: 25%;
        justify-content: center;
        img {
          width: 80%;
          max-width: 80%;
          max-height: 100px;
          object-fit: contain;
        }
      }
    }
  }
`
